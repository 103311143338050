/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 24, 2018 */



@font-face {
    font-family: 'andadaregular';
    src: url('fonts/andada-regular-webfont.woff2') format('woff2'),
         url('fonts/andada-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montserratlight';
    src: url('fonts/montserrat-light-webfont.woff2') format('woff2'),
         url('fonts/montserrat-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'montserratbold';
    src: url('fonts/montserrat-bold-webfont.woff2') format('woff2'),
         url('fonts/montserrat-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'robotoregular';
    src: url('fonts/roboto-regular-webfont.woff2') format('woff2'),
         url('fonts/roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'robotobold';
    src: url('fonts/roboto-bold-webfont.woff2') format('woff2'),
         url('fonts/roboto-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'montserratregular';
    src: url('fonts/montserrat-regular-webfont.woff2') format('woff2'),
         url('fonts/montserrat-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 12, 2018 */



@font-face {
    font-family: 'roboto_slabbold';
    src: url('fonts/robotoslab-bold-webfont.woff2') format('woff2'),
         url('fonts/robotoslab-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'roboto_slablight';
    src: url('fonts/robotoslab-light-webfont.woff2') format('woff2'),
         url('fonts/robotoslab-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'roboto_slabregular';
    src: url('fonts/robotoslab-regular-webfont.woff2') format('woff2'),
         url('fonts/robotoslab-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'roboto_slabthin';
    src: url('fonts/robotoslab-thin-webfont.woff2') format('woff2'),
         url('fonts/robotoslab-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}