@import 'mixins/global';

@media screen and (max-width: 1023px){
	.txt_nomobile{
		display: none;
	}

	//HEADER 
	#Header{
		z-index: 10000;
		height: 60px;
		.header_logo{
			left: 10px;
			top: 30px;
			&:before{
				left: 70%;
				z-index: -1;
			}
			.logo_ocde{
				width: 75px;
				height: 19px;
			}
			&:after{
				display: none;
			}
		}
		&.burgered{
			.burgermenubt{
				background: url("img/bt-close-big-white.svg") no-repeat 0 0;
				background-size:100% 100%;
			}
		}
	}
	#Header .header_links{
		display: none;
		position: absolute;
		left: 0px;
		width: 100%;
		background: $colorBlue;
		@include transform(translate(0, 0));
		z-index: 1000;
		top: 60px;
		border-bottom: 4px solid $colorBlue4;
		.header_link{
			width: 100%;
			display: block;
			height: 60px;
			line-height: 60px;
			padding: 0;
			margin: 0;
			background: #fff;
			color:$colorBlue2;
			border-bottom:1px solid $colorBlue4;
			&:after{
				display: none !important;
			}
			&[data-page="methodology"], &[data-page="press"]{
				height: 45px;
				line-height: 45px;
				font-size: 15px;
				font-family: "robotoregular";
				opacity: 1;
			}
			&.selected{
				background: $colorBlue2;
				color:#fff;
			}
		}
		.link_shares{
			width: 100%;
			display: block;
			height: 60px;
			line-height: 60px;
			padding: 0;
			margin: 0;
			background: #fff;
			color:$colorBlue2;
			top: 0px;
			.share_bt{
				display: inline-block;
				margin: 15px 10px;
				vertical-align: top;
				width: 30px;
				height: 30px;
				cursor: pointer;
				&[data-share="tw"]{
					background: url("img/share-tw-blue.svg") no-repeat 0 0;
					background-size: 100% 100%;
				}
				&[data-share="fb"]{
					background: url("img/share-fb-blue.svg") no-repeat 0 0;
					background-size: 100% 100%;
				}
				&[data-share="in"]{
					background: url("img/share-in-blue.svg") no-repeat 0 0;
					background-size: 100% 100%;
				}
				&:hover{
					opacity: .7;
				}
			}
		}
		&.displayedmobile{
			display: block;
		}
	}
	#Header .burgermenubt{
		display: block;
		z-index: 100;
	}

	//CONTAINER
	#App #Container{
		top: 60px;
	}

	//IMBALANCES CONTAINER
	#App[data-page="imbalances"] #Container{
		&[data-status="comparison"], &[data-status="comparison-ig"]{
			#ContainerFilters{
				height: 80px;
				#ComparisonSelector{
					margin-top: 0px;
					margin-left: 0px;
					height: 20px;
					#ComparisonSelectorSelectbox{
						border:0px solid;
						padding: 4px 26px 0 10px;
						background-position: 93% 9px;
					}
				}
			}
			#ImbalancesGraphWrapper{
				top: 80px;
			}
		}
		&[data-status="comparison-ig"]{
			#ImbalancesGraph{
				top: 40px;
			}
		}
	}

	//IMBALANCES MODAL
	#ImbalancesModal{
		top: 110px;
		width:100%;
		left: 0px;
		right: auto;
		@include transform(translate(0,0));
		border-radius: 0 0 20px 20px;
		border:6px solid $colorBlue;
		padding: 10px 0 8px;
		overflow: initial;
		&:after{
			content: "";
			position: absolute;
			left: 50%;
			top: 100%;
			background:url("img/arrow-title-Metier.png") no-repeat 0 0;
			background-size: 100% 100%;
			width: 30px;
			height: 18px;
			@include transform(translate(-50%, 0));
		}
		.modal_closebt{
			width:14px;
			height: 14px;
			top: 5px;
			right: 5px;
		}
		.talent_label{
			font-size: 18px;
			line-height: 22px;
			padding: 0 20px;
		}
		.talent_description, .talent_highdemandlabel, .og_blocks{
			display: none;
		}
	}
	#Container[data-status="comparison"], #Container[data-status="comparison-ig"]{
		#ImbalancesModal{
			top: 140px;
		}
	} 
	#ImbalancesGraph .modalmobile{
		position: relative;
		top: auto;
		right:auto;
		width:100%;
		opacity: 1;
		display: block;
		border:0px;
		border-radius: 0px;
		margin-top: -14px;
		border-top:1px solid #8d8d8d;
		padding: 0;
		.modal_closebt{
			display: none;
		}
		.talent_label{
			display: none;
		}
		.talent_description{
			padding: 0 10px;
		}
		.talent_highdemandlabel{
			padding:0px 10px;
			margin-top: 4px;
		}
		.og_blocks{
			margin-top: 0px;
			.og_head{
				.head_mobilespecific{
					display: block;
					.mobilespecific_picto{
						background-size: 100% 100%;
						background-repeat: no-repeat;
						display: inline-block;
						width:127px;
						height: 78px;
					}
					.mobilespecific_label{
						font-size:16px;
						line-height: 20px;
						padding: 0 10px;
						color:$colorBlue2;
						display: block;
						text-align: center;
					}
				}
				.block_head{
					min-height: 30px;
					.head_picto{
						display: none;
					}
					.head_label{
						margin-top: 40px;
						.label_str{
							display: none;
						}
					}
				}
			}
			.og_block{
				margin-top: 30px;
				border-radius: 0;
				.block_content{
					padding: 6px;
					.content_indicator{
						.indicator_bar_wrapper{ width: 54%; }
						.indicator_label{
							width: 43%;
							.label_value{
								font-size: 12px;
								line-height: 14px;
							}
						}
					}
				}
			}
		}
	}

	// IMBALANCES FILTERS
	#App[data-page="imbalances"] #Container #ContainerFilters{
		height: 50px;
	}
	#App[data-page="imbalances"] #Container #ContainerFilters #LocationSelector .selector_location_block{
		width: 149px;
		height: 34px;
		border-radius: 5px;
		margin: 8px 2px;
	}
	#App[data-page="imbalances"] #Container #ContainerFilters #LocationSelector .selector_location_block .location_value{
		font-size: 16px;
	}
	#App[data-page="imbalances"] #Container #ContainerFilters #LocationSelector .selector_location_block .location_changebt, #App[data-page="imbalances"] #Container #ContainerFilters #LocationSelector .selector_location_block .location_deletebt{
		font-size: 12px;
	}
	#App[data-page="imbalances"] #Container #ContainerFilters #FiltersTypes{
		display: none;
	}

	//IMBALANCES GRAPH
	#ImbalancesGraphWrapper{
		top: 50px;
		&[data-status="single-shrinked"]{
			overflow: auto;
			.graph_side{
				width:50%;
				&[data-side="indemand"]{
					left: 50%;
				}
			}
		}
	}
	#ImbalancesGraphWrapper .graph_side[data-side="insurplus"] .side_title .imbalances_help{
		display: none;
	}
	#ImbalancesGraphWrapper .graph_side .side_title{
		font-size: 15px;
		line-height: 20px;
		top: 20px;
		width:100%;
		position: absolute;
		@include transform(translate(0%, 0));

		.title_label{
			opacity: .7;
		}
		br{
			display: none;
		}
		&:before{
			width: 28px;
			height: 17px;
			margin-bottom: 0px;
		}
	}
	#ImbalancesGraphWrapper .graph_side[data-side="insurplus"]{
		.side_title{
			left: auto;
			right: 0px;
		}
	}
	#ImbalancesGraphWrapper .graph_side[data-side="indemand"]{
		.side_title{
			left: 0px;
			display: inline-block;
		}
	}
	#ImbalancesGraph{
		width: 100%;
		top: 65px;
		left: 0px;
		@include transform(translate(0,0));
	}
	#ImbalancesGraph.shrinked{
		left: 0px;
		width:100%;
	}
	#ImbalancesGraph .graph_bar_wrapper, #ImbalancesGraph .graph_subbar_wrapper{
		min-height: 30px;
	}
	#ImbalancesGraph .graph_bar_wrapper .graph_bar[data-way="positive"] .bar_label, #ImbalancesGraph .graph_bar_wrapper .graph_subbar[data-way="positive"] .bar_label, #ImbalancesGraph .graph_bar_wrapper .graph_barcomp[data-way="positive"] .bar_label, #ImbalancesGraph .graph_subbar_wrapper .graph_bar[data-way="positive"] .bar_label, #ImbalancesGraph .graph_subbar_wrapper .graph_subbar[data-way="positive"] .bar_label, #ImbalancesGraph .graph_subbar_wrapper .graph_barcomp[data-way="positive"] .bar_label{
		left: 2px;
		right: auto;
		margin-top: -12px;
	}
	#ImbalancesGraph .graph_bar_wrapper .graph_bar[data-way="negative"], #ImbalancesGraph .graph_bar_wrapper .graph_subbar[data-way="negative"], #ImbalancesGraph .graph_bar_wrapper .graph_barcomp[data-way="negative"], #ImbalancesGraph .graph_subbar_wrapper .graph_bar[data-way="negative"], #ImbalancesGraph .graph_subbar_wrapper .graph_subbar[data-way="negative"], #ImbalancesGraph .graph_subbar_wrapper .graph_barcomp[data-way="negative"]{
		.bar_label{
			left: 100%;
			margin-top: -1px;
			margin-left: 2px;
		}
	}
	/*#ImbalancesGraph .graph_bar_wrapper .graph_subbars:after, #ImbalancesGraph .graph_subbar_wrapper .graph_subbars:after{
		display: none;
	}*/
	#ImbalancesGraph .graph_bar_wrapper .graph_subbars, #ImbalancesGraph .graph_subbar_wrapper .graph_subbars{
		margin-top: 15px;
	}
	#ImbalancesGraphWrapper[data-status="single-shrinked"] #ImbalancesGraph .graph_bar_wrapper.prexpanded .graph_bar .bar_label, #ImbalancesGraphWrapper[data-status="single-shrinked"] #ImbalancesGraph .graph_bar_wrapper.expanded .graph_bar .bar_label, #ImbalancesGraphWrapper[data-status="single-shrinked"] #ImbalancesGraph .graph_bar_wrapper .graph_barcomp .bar_label{
		display: none;
	}
	#ImbalancesGraph .graph_bar_wrapper .graph_bar .bar_label, #ImbalancesGraph .graph_bar_wrapper .graph_subbar .bar_label, #ImbalancesGraph .graph_bar_wrapper .graph_barcomp .bar_label, #ImbalancesGraph .graph_subbar_wrapper .graph_bar .bar_label, #ImbalancesGraph .graph_subbar_wrapper .graph_subbar .bar_label, #ImbalancesGraph .graph_subbar_wrapper .graph_barcomp .bar_label{
		left:auto;
		right: 0px;
		margin: -14px -5px 0 0;
	}
	#ImbalancesGraph .graph_bar_wrapper .graph_barcomp, #ImbalancesGraph .graph_subbar_wrapper .graph_barcomp, #ImbalancesGraph .graph_bar_wrapper .graph_barcomp, #ImbalancesGraph .graph_bar_wrapper .graph_subbars .graph_subbar_wrapper .graph_subbar.graph_subbar_comp{
		&[data-align="left"]{
			.bar_label{
				right: auto;
				left: 0px;
				margin: -14px 0px 0 -5px;
			}
		}
	}
	#Container[data-status="comparison-ig"] #ImbalancesGraphWrapper #ImbalancesGraph .graph_bar_wrapper .bar_label{
		left: 50%;
		right: auto;
		@include transform(translate(-50%, 0));
		margin: -20px 0 0;
	}
	#ImbalancesGraphWrapper .graph_side_ig[data-side="moreloca1"] .side_title{
		font-size: 15px;
		white-space: nowrap;
		left: auto;
		right:10px;
		bottom: 0px;
		top: auto;
		text-align: right;
		transform-origin:right center;
		@include transform(translate(0,0) rotate(90deg));
		br{
			display: none;
		}
		&:before{
			left:0px;
			margin-left: -35px;
			top: 50%;
			@include transform(translate(0, -50%) rotate(-180deg));
			width: 26px;
			height: 16px;
		}
	}
	#ImbalancesGraphWrapper .graph_side_ig[data-side="moreloca2"] .side_title{
		font-size: 15px;
		white-space: nowrap;
		left: 100%;
		margin-left: -12px;
		margin-top: -10px;
		top: 0px !important;
		right:auto;
		text-align: left;
		transform-origin:left center;
		@include transform(translate(0,0) rotate(90deg));
		br{
			display: none;
		}
		&:before{
			left:100%;
			margin-left: 10px;
			top: 0px;
			@include transform(translate(0, -50%) rotate(0deg));
			width: 26px;
			height: 16px;
		}
	}
	#ImbalancesGraph .graph_bar_wrapper .graph_subbars .graph_splitter .splitter_legend, #ImbalancesGraph .graph_subbar_wrapper .graph_subbars .graph_splitter .splitter_legend{
		display: none;
	}

	//CHANGING CAREER ARIAN
	#App[data-page="changing_career"]{
		#Header{
			position: fixed;
			left: 0px;
			top: 0px;
		}
		#Cache{
			top: 60px;
		}
		#OGModal{
			width: 95%;
			.og_block .block_content{
				padding:10px;
				.content_indicator{
					.indicator_label{
						width: 45%;
						.label_value{
							font-size: 14px;
						}
					}
					.indicator_bar_wrapper{
						width: 55%;
					}
				}
			}
		}
	}
	#App[data-page="changing_career"] #Container #ContainerArian{
		height: 50px;
		position: fixed;
		top: 60px;
		left: 0px;
		z-index: 1000;
		.arian_wrapper{
			width: 100%;
			.wrapper_arrow{
				display: none;
			}
		}
		.arian_expandmobilebt{
			display: block;
			position: absolute;
			left: 50%;
			top: 100%;
			@include transform(translate(-50%, -4px));
			width: 70px;
			height: 35px;
			border-radius: 0 0 35px 35px;
			background: #fff;
			cursor: pointer;
			&:after{
				content:"";
				position: absolute;
				left: 50%;
				top: 14px;
				@include transform(translate(-50%, 0));
				background: url("img/arrow-dropdown-stroke.svg") no-repeat 0 0;
				background-size:100% 100%;
				width: 24px;
				height: 14px;
			}
		}
		.arian_block{
			width: 98px;
			height: 34px;
			margin: 0 2px;
			top: 8px;
			border-radius: 5px 20px 20px 5px;
			.arian_modifybt{
				display: none;
			}
			.arian_title, .arian_value{
				display: none;
			}
			.arian_mobilelabel{
				display: block;
				position: absolute;
				left: 0px;
				top: 50%;
				@include transform(translate(0, -50%));
				color: #c0c0ae;
				font-size: 12px;
				line-height: 14px;
				text-transform: uppercase;
				text-align: left;
				font-family: "montserratregular";
				padding-left: 20px;
				&:before{
					content: "";
					position: absolute;
					left: 0px;
					top: 50%;
					@include transform(translate(0, -50%));
					background: url("img/bullet-box.svg") no-repeat 0 0;
					background-size: 100% 100%;
					width: 14px;
					height: 8px;
				}
			}
			&.selected{
				.arian_modifybt{
					display: none;
				}
				.arian_mobilelabel{
					color:#fff;
				}
			}
		}

		&.mobileexpanded{
			height: auto;
			padding-bottom: 10px;
			.arian_expandmobilebt{
				&:after{
					@include transform(translate(-50%, 0) rotate(180deg));
				}
			}
			.arian_block{
				width: 100%;
				max-width: 302px;
				margin: 12px 0px;
				height: auto;
				min-height: 53px;
				overflow: unset;
				border-radius: 5px 5px 20px 5px;
				.arian_value, .arian_title, .arian_modifybt{
					display: block;
				}
				.arian_value{
					&.arian_value_desktop{
						display: none !important;
					}
					&.arian_value_mobile{
						&:before{
							content: "";
							position: absolute;
							left: 0px;
							top: 9px;
							@include transform(translate(0, -50%));
							background: url("img/bullet-box.svg") no-repeat 0 0;
							background-size: 100% 100%;
							width: 18px;
							height: 10px;
						}
					}
				}
				.arian_mobilelabel{
					display: none;
				}
				.arian_title{
					position: absolute;
					left: 0px;
					top: -17px;
					color: $colorBlue2;
					font-size: 12px;
					font-family: "montserratregular";
					padding: 0;
					&:before{
						display: none;
					}
				}
				.arian_modifybt{
					right: 8px;
					bottom: 6px;
				}
				.arian_value{
					font-size: 16px;
					line-height: 18px;
					position: relative;
					left: 0px;
					top: 0px;
					padding-left: 24px;
					padding-bottom: 10px;
					padding-right: 85px;
					margin-top: 6px;
				}
				&.selected{
					.arian_modifybt{
						display: block;
					}
				}
			}
		}
	}

	#App[data-page="changing_career"]{
		overflow: auto;
		background:#e8e8e1;
	}

	#App[data-page="changing_career"] #Container{
		.container_block{
			margin-top: 0px;
			top: 110px;
			width: 92%;
			.block_title{
				padding: 0;
			}
			.block_disclaimer{
				padding: 0;
				margin-top: 20px;
			}
		}
	}

	#ChangingcareerTalents{
		height: auto;
		background: #e8e8e1;
		top: 100px !important;
		width: 100%;
		.talents_col{
			width: 100% !important;
			height: auto;
			text-align: center;
			margin:0 !important;
			.talents_title{
				text-align: center;
			}
			.talents_infog{
				display: inline-block;
				margin-top: 10px;
			}
			.talent_graph{
				position: relative;
				margin-top: 10px;
				.talent_row{
					position: relative;
					height: 70px;
					&.displayhelp{
						.help_mobileclosebt{
							display: block !important;
							margin-left: 20px;
							top: 10px;
						}
					}
					.talent_label{
						position: relative;
						left: 50%;
						height: auto;
						@include transform(translate(-50%, 0px));
						margin: 0;
						width: 100%;
						text-align: center;
						z-index: 10;
						.label_value{
							white-space: nowrap;
							position: relative;
							left: 0px;
							top: 0px;
							text-align: center;
							@include transform(translate(0%, 0px));
						}
					}
					.talent_bar_wrapper{
						width: 70%;
						z-index: 10;
						height: 40px;
						.talent_bar .bar_sep{
							.sep_help{
								width: 110px;
								padding: 2px 4px 14px;
								&.tobottom{
									padding: 12px 2px 4px;
								}
							}
						}
					}
				}
			}
		}
	}

	//METHODO - PRESS
	#App[data-page="methodology"] #StaticContent .content_title, #App[data-page="press"] #StaticContent .content_title{
		margin-top: 30px;
	}
}

@media screen and (max-width: 767px){
	//HOME HEADER
	#HomeHeader{
		height: 120px;
		overflow: hidden;
		.header_title{
			left: 50%;
			top: 40px;
			font-size: 34px;
			line-height: 34px;
			@include transform(translate(-50%,0));
			width:250px;
		}
		&:before{
			display: none;
		}
		&:after{
			margin-left: 100px;
		}
	}
	#App[data-page="home"] #HomeContainer .home_mainblock .pickmap_container .pickmaplabel span{
		display: none;
	}

	//HOME
	#App[data-page="home"]{
		overflow: auto;
		#HomeFooter{
			background:#efefeb;
			position: relative;
			.link_shares{
				display: block;
				margin-top: 14px;
			}
			.footer_partners{
				margin-top: 0px;
				.partner{
					display: block;
					position: relative;
					left: 50%;
					@include transform(translate(-50%, 0));
					margin-top: 20px;
					&[data-partner="jpmorgan"]{
						margin-top: 35px;
						margin-bottom: 40px;
					}
				}
			}
		}
	}
	#App[data-page="home"] #HomeContainer{
		height: 400px;
		background:#efefeb;
		#WorldMap{
			height: 360px;
		}
		.home_mainblock{
			top: -30px;
			right: auto;
			left:50%;
			@include transform(translate(-50%,0));
		}
	}
	#App[data-page="home"] #HomeContainer #WorldNavigation{
		display: none;
	}
}
