@import 'mixins/global';

#App[data-page="imbalances"] #Container{
	background: #dfdfd6;
	#Cache{
		position: fixed;
		left: 0px;
		top: $headerHeight;
		width: 100%;
		bottom: 0px;
		display: none;
		background: rgba(0,0,0,0.6);
		z-index: 1000;
		&.displayed{
			display: block;
		}
	}
	#ContainerFilters{
		height: 60px;
		width: 100%;
		background: #fff;
		position: relative;
		#LocationSelector{
			display: inline-block;
			vertical-align: top;
			position: relative;
			.selector_location_block{
				display:inline-block;
				vertical-align:top;
				margin:10px 20px;
				width: 253px;
				height: 42px;
				background: #ebebeb;
				border-radius: 5px;
				position: relative;
				cursor: pointer;
				.location_value{
					position: absolute;
					left: 0px;
					top: 50%;
					@include transform(translate(0, -50%));
					color: #fff;
					font-size: 20px;
					padding-left: 28px;
					text-align: left;
					&:before{
						background: url("img/bullet-box.svg") no-repeat 0 0;
						width: 22px;
						height: 12px;
						background-size: 100% 100%;
						content:"";
						position: absolute;
						left: 0px;
						top: 50%;
						@include transform(translate(0, -50%));
					}
				}
				&.default{
					.location_value{
						color:#858585;
						font-size: 14px;
						padding-left: 34px;
						&:before{
							border:2px solid #afafaf;
							border-radius: 28px;
							background: url("img/button-add.svg") no-repeat 0 0;
							width: 22px;
							height: 22px;
							background-size: 100% 100%;
							content:"";
							position: absolute;
							left: 6px;
							top: 50%;
							@include transform(translate(0, -50%));
						}
					}
					.location_deletebt{
						display: none;
					}
				}
				.location_deletebt{
					right: 10px;
					position: absolute;
					top: 50%;
					@include transform(translate(0, -50%));
					background:url("img/bt-close-small-orange.svg") no-repeat -1px 0px #fff;
					background-size:100% 100%;
					width: 21px;
					height: 21px;
					border-radius: 20px;
				}
				&[data-location="1"]{
					background: $colorCyan;
				}
				&[data-location="2"]{
					background: $colorOrange;
					&.disopacified{
						opacity: 1 !important;
					}
					&.default{
						background: #ebebeb;
					}
				}
				&:hover{
					opacity: .8;
				}
			}
		}
		#FiltersTypes{
			display: inline-block;
			vertical-align: top;
			line-height: 60px;
			.filtertype{
				color:$colorBlue;
				font-family: "robotoregular";
				font-size: 15px;
				display: inline-block;
				vertical-align: top;
				line-height: 60px;
				margin-left: 10px;
				cursor: pointer;
				.filtertype_checkbox{
					width: 16px;
					line-height: 16px;
					height: 16px;
					border-radius: 2px;
					background: $colorBlue;
					display: inline-block;
					vertical-align: middle;
					position: relative;
					&:after{
						content:"";
						position: absolute;
						left: 50%;
						top: 50%;
						@include transform(translate(-50%, -50%));
						display: none;
						width: 16px;
						height: 16px;
						background: url("img/bt-check.svg") no-repeat 0 0;
						background-size: 100% 100%;
					}
				}
				.filtertype_label{
					display: inline-block;
					vertical-align: top;
				}
				&.selected{
					.filtertype_checkbox{
						&:after{
							display: block;
						}
					}
				}
				&:hover{
					opacity: .8;
				}
			}
		}
		#ComparisonSelector{
			display: none;
			vertical-align: top;
			margin-left: 20px;
			margin-top: 10px;
			height: 40px;
			.comparion_selector_label{
				color:$colorBlue2;
				font-size: 14px;
				font-family: "robotoregular";
				display: inline-block;
			}
			#ComparisonSelectorSelectbox{
				background: #fff;
				border:1px solid $colorBlue2;
				color:$colorBlue2;
				padding: 10px 20px 10px 10px;
				background: url("img/arrow-dropdown-stroke.svg") no-repeat 93% 14px;
			}
		}
	}

	&[data-status="comparison"], &[data-status="comparison-ig"]{
		#ComparisonSelector{
			display: inline-block !important;
		}
	}

	&[data-status="comparison-ig"]{
		.graph_side{
			display: none;
		}
		.graph_side_ig{
			display: block;
		}

		.subbars_fakeshadow{
			display: none !important;
		}

		#ImbalancesGraphWrapper{
			background: #dfdfd6;
		}

		#ImbalancesGraph .graph_bar_wrapper .graph_subbars:after, #ImbalancesGraph .graph_subbar_wrapper .graph_subbars:after{
			display: none;
		}
	}
}

#ImbalancesGraphWrapper{
	position: absolute;
	left: 0px;
	top: 60px;
	bottom: 0px;
	overflow: auto;
	width: 100%;
	background: #efefeb;

	&[data-oneskillclicked="true"]{
		#ImbalancesHelp1{
			display: none;
		}
	}

	.graph_side{
		position: absolute;
		height: 100%;
		width: 50%;
		top: 0px;
		@include transition((all), 0.6s, ease-in-out);
		&[data-side="insurplus"]{
			left: 0px;
			background: #dfdfd6;
			box-shadow: inset -50px 0px 50px -50px rgba(150,150,120,0.3);
			.side_title{
				&:before{
					@include transform(translate(-50%, 0) rotate(-180deg));
				}
				.imbalances_help{
					position: absolute;
					left: 50%;
					bottom: 180px;
					@include transform(translate(-50%, 0));
					background: #fff;
					padding: 14px;
					border-radius: 10px;
					text-align: left;
					z-index: 10;
					font-size: 13px;
					line-height: 16px;
					color: $colorBlue2;
					width: 220px;
					box-shadow: 0 0 10px 0 rgba(150,150,120,.2);
					text-transform: none;
					font-family: "robotoregular";
					opacity: 0;
					@include transition((opacity), 0.6s, ease-in-out);
					.bolder{
						font-family: "robotobold";
					}
					&.displayed{
						opacity: 1;
					}
				}
			}
		}
		&[data-side="indemand"]{
			left: 50%;
			background: #efefeb;
			.side_title{
				left: 75%;
			}
		}
		.side_title{
			position: fixed;
			left: 25%;
			top: 70%;
			@include transform(translate(-50%, -50%));
			color: #969678;
			font-size: 36px;
			line-height: 40px;
			text-transform: uppercase;
			font-family: "montserratlight";
			.title_label{
				pointer-events:none;
				opacity: .3;
			}
			&:before{
				content:"";
				position: absolute;
				left:50%;
				bottom: 100%;
				@include transform(translate(-50%, 0));
				margin-bottom: 10px;
				background: url("img/arrow-bg-big.svg") no-repeat 0 0;
				background-size: 100% 100%;
				width: 57px;
				height: 34px;
				opacity: .3;
			}
		}
	}

	.graph_side_ig{
		position: absolute;
		width: 100%;
		display: none;
		.graph_side_ig_loca2, .graph_side_ig_loca1{
			opacity: .3;
		}
		&[data-side="moreloca1"]{
			background:#efefeb;
			.side_title{
				bottom: 14px;
				&:before{
					@include transform(translate(-50%, 0) rotate(-90deg));
					margin-bottom: 20px;
				}
			}
		}
		&[data-side="moreloca2"]{
			background: #dfdfd6;
			.side_title{
				top:14px !important;
				left: auto;
				right: 65%;
				&:before{
					@include transform(translate(-50%, 0) rotate(90deg));
					margin-top: 20px;
					bottom: auto;
					top: 100%;
				}
			}
		}
		.side_title{
			position: absolute;
			left: 65%;
			@include transform(translate(0%, 0%));
			color: #969678;
			font-size: 36px;
			line-height: 40px;
			text-transform: uppercase;
			font-family: "montserratlight";
			.title_label{
				opacity: .3;
			}
			&:before{
				content:"";
				opacity: .3;
				position: absolute;
				left:50%;
				bottom: 100%;
				@include transform(translate(-50%, 0));
				margin-bottom: 10px;
				background: url("img/arrow-bg-big.svg") no-repeat 0 0;
				background-size: 100% 100%;
				width: 57px;
				height: 34px;
			}
		}
	}

	&[data-status="single-shrinked"]{
		overflow: hidden;
		.graph_side{
			width:25%;
			&[data-side="indemand"]{
				left: 25%;
				background: #efefeb;
			}
			.side_title{
				display: none;
			}
		}
		.graph_side_ig{
			.side_title{
				display: none;
			}
		}


		#ImbalancesGraph{
			height: 2000px;
			.graph_bar_wrapper{
				opacity: .2;
				.graph_bar .bar_label, .graph_bars .bar_label{ display: none; }
				&.prexpanded, &.expanded{
					opacity: 1;
					.graph_bar .bar_label, .graph_bars .bar_label{ display: block; }
				}
			}
		}
	}
}

#ImbalancesGraph{
	position:absolute;
	left:50%;
	width:600px;
	height:auto;
	top:15px;
	@include transform(translate(-50%, 0));
	@include transition((all), 0.6s, ease-in-out);
	&.shrinked{
		left:25%;
		width:400px;
	}
	.graph_bar_wrapper, .graph_subbar_wrapper{
		position:relative;
		width:100%;
		min-height:30px;
		left:0px;
		opacity:0;
		cursor:pointer;
		@include transition((all), 0.6s, ease-in-out);
		&[data-nb-children="0"]{
			.graph_subbars{
				display: none !important;
			}
		}
		&.displayed{
			opacity:1;
		}
		&:hover{ 
			.graph_bars .graph_bar, .graph_bars .graph_barcomp{
				.bar_label{
					font-size: 14px;
					font-family: "roboto_slabbold";
				}
				.bar_point, .bar_point1, .bar_point2{
					width: 14px;
					height: 14px;
				}
			}
		}
		&.prexpanded{
			.graph_bars .graph_bar, .graph_bars .graph_barcomp{
				.bar_label{
					font-size: 14px;
				}
				.bar_point, .bar_point1, .bar_point2{
					width: 14px;
					height: 14px;
				}
			}
		}
		.graph_bars{
			position:relative;
			height:16px;
			width:100%;
			top: 15px;
		}
		.graph_bar, .graph_subbar, .graph_barcomp{
			position:absolute;
			top:0px;
			@include transform(translate(0%, 0));
			background:$colorCyan;
			border-radius:10px;
			height:3px;
			width:0%;
			@include transition((all), 0.6s, ease-in-out);
			.bar_label{
				position:absolute;
				left:100%;
				top:50%;
				margin:0 0 0 10px;
				font-size:12px;
				color:$colorBlue2;
				white-space:nowrap;
				opacity:1;
				@include transform(translate(0%, -50%));
			}
			.bar_point{
				position:absolute;
				top:50%;
				@include transform(translate(-50%, -50%));
				width:12px;
				height:12px;
				border-radius:10px;
				background:$colorCyan;
			}
			.bar_point1, .bar_point2{
				position:absolute;
				top:50%;
				@include transform(translate(-50%, -50%));
				width:12px;
				height:12px;
				border-radius:10px;
				background:$colorCyan;
			}
			.bar_point1{
			}
			.bar_point2{
				background:$colorOrange;
			}
			&[data-way="positive"]{
				left:50%;
				.bar_point{
					left:100%;
				}
				.bar_label{
					left: auto;
					right: 100%;
					margin:0 10px 0 0px;
				}
			}
			&[data-way="negative"]{
				right:50%;
				.bar_point{
					right:100%;
					@include transform(translate(50%, -50%));
				}
			}
		}
		.graph_barcomp{
			&[data-way="decreasing"], &[data-way="imbalance-loca1"]{
				/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f39200+0,28a9ef+100 */
				background: #f39200; /* Old browsers */
				background: -moz-linear-gradient(left, #f39200 0%, #28a9ef 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(left, #f39200 0%,#28a9ef 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to right, #f39200 0%,#28a9ef 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f39200', endColorstr='#28a9ef',GradientType=1 ); /* IE6-9 */
				.bar_point1{
					right:0px;
					@include transform(translate(50%, -50%));
				}
				.bar_point2{
					left:0px;
					@include transform(translate(-50%, -50%));
				}
			}
			&[data-way="increasing"], &[data-way="imbalance-loca2"]{
				/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#28a9ef+0,f39200+100 */
				background: #28a9ef; /* Old browsers */
				background: -moz-linear-gradient(left, #28a9ef 0%, #f39200 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(left, #28a9ef 0%,#f39200 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to right, #28a9ef 0%,#f39200 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#28a9ef', endColorstr='#f39200',GradientType=1 ); /* IE6-9 */
				.bar_point1{
					left:0px;
					@include transform(translate(-50%, -50%));
				}
				.bar_point2{
					right:0px;
					@include transform(translate(50%, -50%));
				}
			}
			&[data-way="imbalance-loca1"]{
				.bar_point1{
					left:auto;
					right:0px;
					@include transform(translate(50%, -50%));
				}
			}
			&[data-way="imbalance-loca2"]{
				.bar_point2{
					left:auto;
					right:0px;
					@include transform(translate(50%, -50%));
				}
			}
		}
		.graph_subbars{
			position:relative;
			left:0px;
			width:100%;
			padding:60px 0 0px;
			display:none;
			margin-top:20px;
			margin-bottom:20px;
			background:#fff;
			border-top:1px solid #8d8d8d;
			border-bottom:1px solid #8d8d8d;
			&:before{
				content: "";
				position: absolute;
				right:100%;
				top: 0px;
				width:500px;
				background: #fff;
				height: calc(100% + 2px);
				margin-top: -1px;
				border-top:1px solid #8d8d8d;
				border-bottom:1px solid #8d8d8d;
			}
			.subbars_fakeshadow{
				left: 0px;
				top: 0px;
				width: 50%;
				height: 100%;
				background:#fff;
				box-shadow: inset -50px 0px 50px -50px rgba(150,150,120,0.3);
				position: absolute;
				z-index: 0;
				top: 0px;
			}
			.subbars_title{
				color: #969678;
				font-size: 18px;
				font-family: "montserratlight";
				position: absolute;
				top: 14px;
				left: 50%;
				@include transform(translate(-50%, 0));
				width: 100%;
				text-align: center;
				z-index: 1;
				text-transform: uppercase;
			}
			.subbars_fakefullwidth{
				background: #fff;
				position: absolute;
				left: 100%;
				top: 0px;
				height: calc(100% + 2px);
				border-top:1px solid #8d8d8d;
				border-bottom:1px solid #8d8d8d;
				margin-top: -1px;
			}
			.graph_splitter{
				position: relative;
				height: 30px;
				/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#efefeb+0,ffffff+100 */
				background: #efefeb; /* Old browsers */
				background: -moz-linear-gradient(top, #efefeb 0%, #ffffff 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top, #efefeb 0%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom, #efefeb 0%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#efefeb', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
				margin-top: -10px;
				margin-bottom: 0px;
				&:before{
					position: absolute;
					right: 100%;
					top: 0px;
					width: 500px;
					/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#efefeb+0,ffffff+100 */
					background: #efefeb; /* Old browsers */
					background: -moz-linear-gradient(top, #efefeb 0%, #ffffff 100%); /* FF3.6-15 */
					background: -webkit-linear-gradient(top, #efefeb 0%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to bottom, #efefeb 0%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#efefeb', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
					height: 30px;
					content: "";
				}
				.splitter_legend{
					position: absolute;
					left: 0px;
					font-family: "montserratlight";
					font-size: 14px;
					color: #969678;
					text-transform: uppercase;
					&:before{
						content:"";
						position: absolute;
						right: 100%;
						margin-right: 10px;
						top: 0px;
						background: url("img/arrow-bg-small.svg") no-repeat 0 0;
						background-size: 100% 100%;
						width: 15px;
						height: 24px;
					}
					&[data-legend="co1"]{
						bottom: 100%;
						margin-bottom: 10px;
					}
					&[data-legend="co2"]{
						top: 10px;
						&:before{
							@include transform(rotate(180deg));
						}
					}
				}
				.splitter_fake{
					position: absolute;
					left: 100%;
					top: 0px;
					/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#efefeb+0,ffffff+100 */
					background: #efefeb; /* Old browsers */
					background: -moz-linear-gradient(top, #efefeb 0%, #ffffff 100%); /* FF3.6-15 */
					background: -webkit-linear-gradient(top, #efefeb 0%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to bottom, #efefeb 0%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#efefeb', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
					height: 30px;
				}
				&[data-splitting="co1"]{
					background: #fff;
					height: 10px;
					.splitter_fake, &:before{
						display: none;
					}
					.splitter_legend[data-legend="co2"]{
						display: none;
					}
				}
				&[data-splitting="co2"]{
					height: 30px;
					.splitter_legend[data-legend="co1"]{
						display: none;
					}
				}
			}
			.graph_subbar_wrapper{
				opacity:1;
				z-index:10;
				min-height:40px;
				.graph_subbar{
					.bar_label{
						
					}
					&[data-way="decreasing"]{
						.bar_point1{
							right:0px;
							@include transform(translate(50%, -50%));
						}
						.bar_point2{
							left:0px;
							@include transform(translate(-50%, -50%));
						}
					}
					&[data-way="increasing"]{
						.bar_point1{
							left:0px;
							@include transform(translate(-50%, -50%));
						}
						.bar_point2{
							right:0px;
							@include transform(translate(50%, -50%));
						}
					}
					&[data-way="imbalance-loca1"]{
						.bar_point1{
							left:auto;
							right:0px;
							@include transform(translate(50%, -50%));
						}
					}
					&[data-way="imbalance-loca2"]{
						.bar_point2{
							left:auto;
							right:0px;
							@include transform(translate(50%, -50%));
						}
					}
					&.graph_subbar_comp{
						&[data-way="decreasing"], , &[data-way="imbalance-loca1"]{
							/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f39200+0,28a9ef+100 */
							background: #f39200; /* Old browsers */
							background: -moz-linear-gradient(left, #f39200 0%, #28a9ef 100%); /* FF3.6-15 */
							background: -webkit-linear-gradient(left, #f39200 0%,#28a9ef 100%); /* Chrome10-25,Safari5.1-6 */
							background: linear-gradient(to right, #f39200 0%,#28a9ef 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
							filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f39200', endColorstr='#28a9ef',GradientType=1 ); /* IE6-9 */
						}
						&[data-way="increasing"], , &[data-way="imbalance-loca2"]{
							/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#28a9ef+0,f39200+100 */
							background: #28a9ef; /* Old browsers */
							background: -moz-linear-gradient(left, #28a9ef 0%, #f39200 100%); /* FF3.6-15 */
							background: -webkit-linear-gradient(left, #28a9ef 0%,#f39200 100%); /* Chrome10-25,Safari5.1-6 */
							background: linear-gradient(to right, #28a9ef 0%,#f39200 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
							filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#28a9ef', endColorstr='#f39200',GradientType=1 ); /* IE6-9 */
						}
					}
				}
			}
		}
		&.expanded{
			.graph_bar{
				.bar_label{
					opacity:1;
					@include transition((opacity), 0.3s, ease-in-out, 0.6s);
				}
			}
			.graph_subbars{
				display:block;
				.graph_subbar{
					.bar_label{
						opacity:1;
						@include transition((opacity), 0.3s, ease-in-out, 0.6s);
					}
				}
			}
		}
	}
}

#ImbalancesModal{
	overflow: auto;
}

#ImbalancesModal, .modalmobile{
	position:fixed;
	top:160px;
	right:40px;
	width:500px;
	padding:14px 0 0;
	@include transform(translate(0%, 0%));
	@include transition((all), 0.6s, ease-in-out);
	background:#fff;
	border:6px solid $colorBlue;
	display: none;
	opacity: 0;
	border-radius:20px;
	&.displayed{
		display: block;
	}
	&.disopacified{
		opacity: 1;
	}
	.modal_closebt{
		position: absolute;
		right: 10px;
		top: 10px;
		cursor: pointer;
		width: 24px;
		height: 24px;
		background: url("img/bt-close-big.svg") no-repeat 0 0;
		background-size: 100% 100%;
		&:hover{
			opacity: .8;
		}
	}
	.talent_label{
		font-size:22px;
		line-height:26px;
		color: $colorBlue;
	}
	.talent_description{
		color:#636363;
		font-family: "robotoregular";
		font-size:14px;
		line-height:18px;
		margin-top:10px;
		padding:0 20px;
	}
	.talent_highdemandlabel{
		font-size:14px;
		font-family: "robotobold";
		color: $colorBlue;
		line-height:18px;
		margin-top:10px;
		padding:0 20px;
	}
	.og_blocks{
		width:100%;
		margin-top:10px;
		.og_head{
			width: 100%;
			.head_mobilespecific{
				display: none;
			}
			.block_head{
				display: inline-block;
				vertical-align: top;
				width: 33%;
				opacity: .2;
				min-height: 80px;
				cursor: pointer;
				position: relative;
				.head_picto{
					position: absolute;
					width: 127px;
					height: 78px;
					left: 50%;
					top: 0px;
					background-size: 100% 100%;
					background-repeat: no-repeat;
					background-position: center center;
					@include transform(translate(-50%, 0));
				}
				.head_label{
					font-size: 16px;
					color:$colorBlue;
					position: relative;
					margin-top: 110px;
					.label_num{
						position: absolute;
						bottom: 100%;
						left:50%;
						@include transform(translate(-50%, 0));
						font-size: 16px;
						text-align: center;
						background: $colorBlue;
						width: 25px;
						height: 25px;
						border-radius: 25px;
						line-height: 25px;
						color:#fff;
					}
				}
				&:hover{
					opacity: .4;
				}
				&.selected{
					opacity: 1;
				}
			}
		}
	}
}

#LocationSelectorModal{
	position: fixed;
	left: 50%;
	top: 50%;
	@include transform(translate(-50%, -50%));
	background:#fff;
	border:6px solid $colorBlue;
	display: none;
	border-radius:20px;
	padding: 60px 10px 40px;
	z-index: 2000;
	width: 280px;
	height: 410px;
	&.displayed{
		display: block;
	}
	.modal_content, .modal_cancelbt, .modal_validbt{
		display: none;
	}

	.modal_content{
		.content_title{
			position: relative;
			min-height: 10px;
			.title_country{
				font-size: 20px;
				color: $colorBlue3;
			}
			.smaller{
				font-size: 11px;
				font-family: "montserratlight";
				color:$colorBlue2;
				text-transform: uppercase;
				position: relative;
			}
			&:before{
				content:"";
				position: absolute;
				left: 50%;
				bottom: 100%;
				margin-bottom: 10px;
				background: url("img/map-pin-orange@2x.png") no-repeat 0 0;
				width: 35px;
				height: 42px;
				background-size: 100% 100%;
				@include transform(translate(-50%, 0));
			}
		}
		.select_label{
			font-size: 11px;
			font-family: "montserratlight";
			color:$colorBlue2;
			text-transform: uppercase;
			position: relative;
			margin-top: 20px;
		}
		.location_selectbox{
			margin-top: 5px;
			width: 212px;
			height: 36px;
			border:1px solid $colorBlue2;
			background: #fff;
			color:$colorBlue2;
			border-radius: 3px;
			padding: 0px 10px;
			background: url("img/arrow-dropdown-solid.svg") no-repeat 95% 14px;
			option{

			}
		}
	}
	.modal_actions{
		position: absolute;
		left: 0px;
		bottom: 40px;
		width: 100%;
		.modal_cancelbt, .modal_validbt{
			width: 135px;
			height: 40px;
			border-radius:3px;
			cursor: pointer;
			color:#fff;
			line-height: 38px;
			font-size: 14px;
			font-family: "montserratbold";
			text-decoration: none;
		}
		.modal_cancelbt{
			background: #bcc5c7;
		}
		.modal_validbt{
			background: $colorOrange;
		}
	}

	&[data-location="1"]{
		.modal_content[data-location="1"]{
			display: block;
			.content_title{
				&:before{
					background: url("img/map-pin-cyan@2x.png") no-repeat 0 0;
					background-size:100% 100%;
				}
			}
		}
	}

	&[data-location="2"]{
		.modal_content[data-location="2"]{
			display: block;
		}
	}

	&[data-haslocation="true"]{
		.modal_validbt{
			display: inline-block;
		}
	}

	&[data-haslocation="false"]{
		.modal_cancelbt{
			display: inline-block;
		}
	}
}