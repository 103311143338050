@import 'mixins/global';

#App[data-page="methodology"], #App[data-page="press"]{
	overflow: unset;
	background: #dfdfd6;
	#Container{
		background: #dfdfd6;
		bottom: auto;
	}

	#StaticContent{
		background: #fff;
		width: 100%;
		display: inline-block;
		max-width: 780px;
		padding: 0px 20px 40px;
		color:$colorBlue2;
		.content_inner{
			max-width: 500px;
			width: 100%;
			display: inline-block;
			vertical-align: top;
		}
		.content_title{
			font-size: 24px;
			line-height: 28px;
			margin-top: 60px;
			margin-bottom: 20px;
			text-align: center;
		}
		.content_paragraph{
			font-size: 15px;
			line-height: 18px;
			font-family: "robotoregular";
			text-align: left;
			.content_link_inline{
				color: $colorCyan;
				text-decoration: underline;
				display: inline;
				font-family: "robotoregular";
			}
		}
		.content_link{
			color: $colorCyan;
			text-decoration: underline;
			display: block;
			margin-bottom: 14px;
			text-align: left;
			font-size: 15px;
			font-family: "robotoregular";
		}
	}
}