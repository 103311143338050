@import 'mixins/global';

#App[data-page="changing_career"] #Container{
	background: #e8e8e1;
	#ContainerArian{
		height: 140px;
		width: 100%;
		background: #fff;
		position: relative;
		.arian_wrapper{
			width: 1024px;
			display: inline-block;
			vertical-align: top;
			height: 100%;
			position: relative;
			.wrapper_arrow{
				position: absolute;
				left: 0%;
				bottom: 0px;
				z-index: 100;
				@include transform(translate(-50%, 0));
				@include transition((left), 0.6s, ease-in-out);
				background:url("img/arrow-selector-ariane.svg") no-repeat 0 0;
				background-size: 100% 100%;
				width: 73px;
				height: 32px;
				margin-top: -18px;
				opacity: 0;
			}
		}
		.arian_expandmobilebt{
			display: none;
		}
		.arian_block{
			display: inline-block;
			vertical-align: top;
			width: 328px;
			height: 113px;
			background: #efefeb;
			border-radius: 5px 5px 20px 20px;
			position: relative;
			top: 13px;
			margin: 0 5px;
			cursor: pointer;
			.arian_mobilelabel{
				display: none;
			}
			.arian_title{
				position: absolute;
				left: 0px;
				padding-left: 30px;
				top: 10px;
				font-family: "montserratlight";
				color: #d5d5c9;
				font-size: 20px;
				text-transform: uppercase;
				&:before{
					content: "";
					display: none;
					position: absolute;
					left: 0px;
					top: 2px;
					background: url("img/bullet-box.svg") no-repeat 0 0;
					background-size: 100% 100%;
					width: 22px;
					height: 12px;
				}
			}
			.arian_value{
				position: absolute;
				left: 30px;
				top: 30px;
				color: #fff;
				font-size: 22px;
				line-height: 25px;
				text-align: left;
				padding-right: 20px;
				&.arian_value_mobile{
					display: none;
				}
				.value_group{
					font-size: 13px;
					font-family: "robotobold";
					line-height: 16px;
					margin-top: 6px;
					text-decoration: underline;
					cursor: pointer;
				}
			}
			.arian_modifybt{
				display: none;
				position: absolute;
				right: 10px;
				bottom: 10px;
				background: #fff;
				color:$colorEmeraud;
				font-size: 14px;
				width: 76px;
				height: 21px;
				line-height: 21px;
				font-family: "robotobold";
				cursor: pointer;
				border-radius: 10px;
				text-decoration: none;
				text-indent: 10px;
				&:before{
					background: url("img/bt-close-small.svg") no-repeat 0 0;
					background-size: 100% 100%;
					content:"";
					position: absolute;
					width: 21px;
					height: 21px;
					left: 0px;
					top: 50%;
					@include transform(translate(0%, -50%));
				}
				&:hover{
					opacity: .8;
				}
			}
			&.selected{
				opacity: 1;
				background: $colorEmeraud;
				.arian_title{
					color: #fff;
					font-size: 12px;
					&:before{
						display: block;
					}
					.threepts{
						display: none;
					}
				}
				.arian_modifybt{
					display: block;
				}
			}

			&[data-block="currentwork"]{
				&.selected{
					background: $colorCyan;
				}
				.arian_modifybt{
					color:$colorCyan;
					&:before{
						background: url("img/bt-close-small-cyan.svg") no-repeat 0 0;
						background-size: 100% 100%;
					}
				}
			}

			&[data-block="wish"]{
				&.selected{
					background: $colorOrange;
				}
				.arian_modifybt{
					color:$colorOrange;
					&:before{
						background: url("img/bt-close-small-orange.svg") no-repeat 0 0;
						background-size: 100% 100%;
					}
				}
			}
		}
	}

	.container_block{
		position: absolute;
		left: 50%;
		top: 180px;
		opacity: 0;
		z-index: -1;
		@include transform(translate(-50%, 300px));
		@include transition((all), 0.6s, ease-in-out);
		&.displayed{
			opacity: 1;
			z-index: 100;
			@include transform(translate(-50%, 0px));
		}
		&.leave{
			opacity: 0;
			@include transition((all), 0.3s, ease-in-out);
		}
		.location_selectbox{
			margin-top: 20px;
			width: 212px;
			height: 36px;
			border:1px solid $colorBlue2;
			color:$colorBlue2;
			border-radius: 3px;
			padding: 0px 10px;
			background: url("img/arrow-dropdown-solid.svg") no-repeat 95% 14px #fff;
		}
		.block_title{
			font-size: 30px;
			line-height: 34px;
			color: $colorBlue;
		}
		.block_subtitle{
			font-size: 12px;
			font-family: "montserratlight";
			color:$colorBlue2;
			text-transform: uppercase;
			position: relative;
			margin-top: 80px;
			.subtitle_picto{
				position: absolute;
				left: 50%;
				bottom: 100%;
				margin-bottom: 10px;
				@include transform(translate(-50%, 0));
				width: 127px;
				height: 78px;
				background-size: 100% 100%;
				background-repeat: no-repeat;
				background-position: center center;
			}
		}
		.occupation_label{
			color:$colorBlue;
			font-size: 22px;
			line-height: 26px;
			max-width: 300px;
		}
		.block_input{
			border: 1px solid #9ac0d8;
			border-radius: 3px;
			width: 263px;
			height: 40px;
			margin-top: 20px;
			outline: 0px;
			color:$colorBlue;
			padding: 0 5px;
			font-size: 15px;
			font-family: "roboto_slabregular";
		}
		.block_disclaimer{
			font-size: 20px;
			line-height: 24px;
			color:$colorBlue2;
			margin-top: 40px;
		}
		.changing_career_validbt{
			background:$colorOrange;
			margin-top: 20px;
			color:#fff;
			width: 122px;
			height: 40px;
			border-radius: 3px;
			position: relative;
			left: 50%;
			@include transform(translate(-50%, 0));
			text-transform: uppercase;
			font-family: "montserratbold";
			font-size: 14px;
			line-height: 40px;
			cursor: pointer;
			text-decoration: none;
			display: block;
			&:hover{
				opacity: .8;
			}
		}
		.myoccupation_cancelbt, .wishoccupation_cancelbt{
			text-decoration: underline;
			font-size: 14px;
			font-family: "robotoregular";
			color:$colorBlue2;
			margin-top: 10px;
			display: inline-block;
			cursor: pointer;
		}
	}

	&[data-block="location"]{
		#ContainerArian{
			.arian_wrapper{
				.wrapper_arrow{
					opacity: 1;
					left:16.5%;
				}
			}
		}
	}
	&[data-block="currentwork"], &[data-block="currentworkValidate"]{
		#ContainerArian{
			.arian_wrapper{
				.wrapper_arrow{
					opacity: 1;
					left:50%;
				}
			}
		}
	}
	&[data-block="wish"], &[data-block="wishValidate"]{
		#ContainerArian{
			.arian_wrapper{
				.wrapper_arrow{
					opacity: 1;
					left:82.5%;
				}
			}
		}
	}
}

#ChangingcareerOccupations{
	margin-top:20px;
	.occupation_block{
		display:inline-block;
		width:45%;
		margin:0 1%;
		vertical-align:top;
		font-size:14px;
		line-height:18px;
		.occupation_selectbox{
			max-width:300px;
		}
		.occupation_group{
			margin-top:10px;
			display:none;
			&.displayed{
				display:block;
			}
		}
	}
}

#ChangingcareerTalents{
	margin-top:40px;
	height:300px;
	width: 100%;
	max-width: 1000px;
	top: 160px !important;
	.talents_wrapper{
		position: relative;
		left: 50%;
		width: 100%;
		height: 100%;
		@include transform(translate(-50%, 0%));
	}
	.talents_col{
		display:inline-block;
		width:50%;
		vertical-align:top;
		height:100%;
		position:relative;
		&[data-col="1"]{
			width:28%;
			margin-left: 2%;
		}
		&[data-col="2"]{
			width:70%;
		}
		.talents_title{
			font-size:24px;
			line-height:28px;
			position:relative;
			text-align: left;
			color:$colorBlue;
			@include transform(translate(0%, 0%));
		}
		.talents_infog{
			margin-top: 20px;
			width: 210px;
			height: 86px;
			background: #efefeb;
			border-radius: 20px;
			position: relative;
			&:after{
				content: "";
				background: url("img/career-caption.svg") no-repeat 0 0;
				background-size: 100% 100%;
				position: absolute;
				left: 50%;
				top: 56px;
				@include transform(translate(-50%, -50%));
				width: 124px;
				height: 22px;
			}
			.infog_label{
				font-size: 12px;
				font-family: "montserratlight";
				text-transform: uppercase;
				color: $colorBlue2;
				position: absolute;
				top: 14px;
				line-height: 15px;
				&[data-label="1"]{
					left: 20px;
				}
				&[data-label="2"]{
					left: 120px;
				}
			}
		}
		.talent_graph{
			position:absolute;
			left:50%;
			top:0%;
			@include transform(translate(-50%, 0%));
			width:100%;
			.talent_row{
				width:100%;
				height:60px;
				z-index: 10;
				position: relative;
				&.opacified{
					z-index: 1;
					opacity: .4;
				}
				&.displayhelp{
					.sep_help{
						display: block !important;
					}
				}
				.talent_label{
					width:21%;
					height:100%;
					display:inline-block;
					position:relative;
					vertical-align:top;
					margin-right:2%;
					color:$colorBlue;
					font-family: "robotoregular";
					font-size: 16px;
					line-height: 18px;
					.label_value{
						position:absolute;
						left:0px;
						top:50%;
						text-align:left;
						@include transform(translate(0, -50%));
						display: inline-block;
						.talent_help{
							position: relative;
							left: 0px;
							top: -2px;
							width: 18px;
							height: 18px;
							font-family: "montserratbold";
							font-size: 13px;
							background:$colorBlue;
							color:#fff;
							text-align: center;
							line-height: 18px;
							border-radius: 18px;
							display: inline-block;
							cursor: pointer;
						}
					}
				}
				.talent_bar_wrapper{
					display:inline-block;
					vertical-align:top;
					height:100%;
					width:67%;
					position:relative;
					.help_mobileclosebt{
						position: absolute;
						left: 100%;
						display: none;
						background:url("img/bt-close-big.svg") no-repeat 0 0;
						background-size: 100% 100%;
						width: 18px;
						height: 18px;
					}
					.talent_bar{
						width:100%;
						border-radius:20px;
						background:#fff;
						height:5px;
						position:absolute;
						left:0px;
						top:50%;
						@include transform(translate(0, -50%));
						.bar_sep{
							position: absolute;
							top: 50%;
							@include transform(translate(-50%,-50%));
							width: 4px;
							background: #fff;
							height: 17px;
							border-radius: 10px;
							&[data-sep="0"]{ left: 0%; }
							&[data-sep="1"]{ left: 14.28%; }
							&[data-sep="2"]{ left: 28.56%; }
							&[data-sep="3"]{ left: 42.84%; }
							&[data-sep="4"]{ left: 57.13%; }
							&[data-sep="5"]{ left: 71.42%; }
							&[data-sep="6"]{ left: 85.7%; }
							&[data-sep="7"]{ left: 100%; }
							.sep_help{
								background:#fff;
								padding: 6px 6px 14px;
								border-radius: 10px;
								width: 134px;
								height: auto;
								position: absolute;
								left: 50%;
								bottom: 100%;
								margin-bottom: 20px;
								@include transform(translate(-50%, 0));
								display: none;
								.help_text{
									color:#333333;
									font-size: 12px;
									font-family: "robotoregular";
								}
								.help_level{
									background:$colorBlue;
									position: absolute;
									width: 66px;
									height: 20px;
									line-height: 18px;
									border-radius: 20px;
									color:#fff;
									left: 50%;
									top: 100%;
									@include transform(translate(-50%, -10px));
									text-transform: uppercase;
									font-size: 12px;
									font-family: "robotobold";
									&:after{
										content:"";
										position: absolute;
										left: 50%;
										top: 100%;
										@include transform(translate(-50%, 0));
										width: 0;
										height: 0;
										border-style: solid;
										border-width: 6px 6px 0 6px;
										border-color: $colorBlue transparent transparent transparent;
									}
								}
								&.tobottom{
									top: 100%;
									bottom: auto;
									margin-top: 20px;
									padding: 14px 6px 6px;
									.help_level{
										bottom: 100%;
										top: auto;
										@include transform(translate(-50%, 10px));
										&:after{
											top: auto;
											bottom: 100%;
											border-width: 0 6px 6px 6px;
											border-color: transparent transparent $colorBlue transparent;
										}
									}
								}
							}
						}
						.bar_legend{
							position:absolute;
							top:50%;
							@include transform(translate(0, -50%));
							&.legend_less{
								left:8px;
							}
							&.legend_plus{
								right:8px;
							}
						}
						.bar_inner{
							position:absolute;
							left:0px;
							right:0px;
							height:100%;
							.bar_arrow{
								position:absolute;
								height:6px;
								/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#28a9ef+0,f39200+100 */
								background: #28a9ef; /* Old browsers */
								background: -moz-linear-gradient(left, #28a9ef 0%, #f39200 100%); /* FF3.6-15 */
								background: -webkit-linear-gradient(left, #28a9ef 0%,#f39200 100%); /* Chrome10-25,Safari5.1-6 */
								background: linear-gradient(to right, #28a9ef 0%,#f39200 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
								filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#28a9ef', endColorstr='#f39200',GradientType=1 ); /* IE6-9 */
								top:50%;
								@include transform(translate(0, -50%));
								@include transition((all), 0.3s, ease-in-out);
								&:after{
									content:"";
									position:absolute;
									left:100%;
									top:50%;
									background:url("img/career-arrow-end.svg") no-repeat 0 0;
									background-size:100% 100%;
									width:16px;
									height:22px;
									@include transform(translate(-50%, -50%));
								}
								&:before{
									content:"";
									position: absolute;
									width: 14px;
									height: 14px;
									border-radius: 12px;
									background: $colorCyan;
									left: 0px;
									top: 50%;
									@include transform(translate(-50%, -50%));
								}
							}
						}
					}
				}
			}
		}
	}
}

#Cache{
	position: fixed;
	left: 0px;
	top: $headerHeight;
	width: 100%;
	bottom: 0px;
	background: rgba(0,0,0,0.6);
	display: none;
	z-index: 9000;
	&.displayed{
		display: block;
	}
}

#OGModal{
	width: 435px;
	padding: 14px 0px 0px;
	border:5px solid $colorBlue;
	border-radius:20px;
	@include transform(translate(-50%, -1500px));
	@include transition((all), 0.6s, ease-in-out);
	position: fixed;
	left: 50%;
	top: 50%;
	opacity: 0;
	background: #fff;
	z-index: 10000;
	&.displayed{
		opacity: 1;
		@include transform(translate(-50%, -50%));
	}
	.ogmodal_closebt{
		position: absolute;
		right: 10px;
		top: 8px;
		background: url("img/bt-close-big.svg") no-repeat 0 0;
		background-size: 100% 100%;
		width: 18px;
		height: 18px;
		cursor: pointer;
	}
	.ogmodal_subtitle{
		font-size: 12px;
		font-family: "robotobold";
		color: $colorBlue2;
		text-transform: uppercase;
	}
	.ogmodal_occupationgroup{
		font-size: 22px;
		line-height: 26px;
		color:$colorBlue;
		margin-top: 90px;
		.occupationgroup_label{
			position: relative;
			padding: 0 30px;
			.occupationgroup_label_picto{
				position: absolute;
				left: 50%;
				bottom: 100%;
				@include transform(translate(-50%, 0));
				width: 127px;
				height: 78px;
				margin-bottom: 8px;
				background-size: 100% 100%;
			}
			.occupationgroup_label_growth{
				font-size: 16px;
				font-family: "robotobold";
				padding-left: 24px;
				position: relative;
				margin-top: 2px;
				display: inline-block;
				&:before{
					content:"";
					position: absolute;
					left: 0px;
					top: 4px;
					width: 18px;
					height: 18px;
					background-size: 100% 100%;
					border-radius: 2px;
				}
				&[data-growth="demand"]{
					color:#aabf16;
					&:before{
						background: url("img/icon-hard-to-fill.svg") no-repeat 0 0;
					}
				}
				&[data-growth="surplus"]{
					color:#e94e1b;
					&:before{
						background: url("img/icon-easy-to-fill.svg") no-repeat 0 0;
					}
				}
			}
		}

	}
	.og_block{
		margin-top: 15px;
		&:before{
			display: none;
		}
	}
	.og_block .block_content .content_indicator .indicator_label{
		width: 38%;
	}
	.og_block .block_content .content_indicator .indicator_bar_wrapper{
		width: 59%;
	}
	.og_block .block_content .content_indicator .indicator_bar_wrapper .indicator_bar .bar_point{
		background: url("img/level-pin-orange.svg") no-repeat 0 0;
	}
}

body#ChangingCareer{
	.ui-autocomplete.ui-menu{
		max-width: 263px;
		max-height: 240px;
		border:1px solid #9ac0d8;
		border-radius:3px;
		.ui-menu-item{
			height: auto;
			div{
				padding: 6px 10px;
				line-height: 20px;
			}
		}
	}
}