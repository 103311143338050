@import 'mixins/global';

#HomeHeader{
	background:$colorBlue4;
	width:100%;
	height:$homeHeaderHeight;
	position:relative;
	left:0px;
	top:0px;
	color:#fff;
	&:before, &:after{
		content:"";
		position: absolute;
		left: 50%;
		@include transform(translate(-50%, 0));
	}
	&:before{
		background: url("img/homepage-deco1@2x.png") no-repeat 0 0;
		background-size: 100% 100%;
		width: 264px;
		height: 156px;
		margin-left: -270px;
	}
	&:after{
		background: url("img/homepage-deco2@2x.png") no-repeat 0 0;
		background-size: 100% 100%;
		width: 235px;
		height: 156px;
		margin-left: 240px;
	}
	.header_title{
		position:absolute;
		left:50%;
		@include transform(translate(-50%, 0));
		font-size:46px;
		top:60px;
		line-height:46px;
		z-index: 10;
		&:before{
			content:"";
			position: absolute;
			left: 0px;
			bottom: 100%;
			background: url("img/logo-OCDE@2x.png") no-repeat 0 0;
			background-size: 100% 100%;
			width: 107px;
			height: 27px;
		}
		.smaller{
			font-family: "roboto_slabthin";
		}
	}
}

#Header{
	width:100%;
	height:$headerHeight;
	background:$colorBlue4;
	position:absolute;
	left:0px;
	top:0px;
	color:#fff;
	box-shadow: inset 0px -30px 30px -30px rgba(3,98,157,1);

	.burgermenubt{
		display: none;
		position: absolute;
		top: 50%;
		right: 10px;
		@include transform(translate(0, -50%));
		cursor: pointer;
		text-decoration: none;
		color: #fff;
		font-size: 30px;
		background: url("img/burgermenu.svg") no-repeat 0 0;
		background-size: 100% 100%;
		width:32px;
		height: 32px;
	}

	.header_logo{
		position:absolute;
		left:150px;
		top:38px;
		font-size: 32px;
		cursor: pointer;
		color:#fff;
		display: inline-block;
		text-decoration: none;
		z-index: 10;
		.smaller{
			font-family: "roboto_slabthin";
		}
		.logo_ocde{
			position: absolute;
			left: 0px;
			bottom: 100%;
			margin-bottom: 10px;
			background: url("img/logo-OCDE@2x.png") no-repeat 0 0;
			background-size: 100% 100%;
			width: 90px;
			height: 23px;
		}
		&:after{
			background: url("img/header-deco1@2x.png") no-repeat 0 0;
			background-size: 100% 100%;
			width: 143px;
			height: 82px;
			position: absolute;
			content: "";
			right:100%;
			top: -40px;
			margin-right: 10px;
		}
		&:before{
			background: url("img/header-deco2@2x.png") no-repeat 0 0;
			background-size: 100% 100%;
			width: 120px;
			height: 82px;
			position: absolute;
			content: "";
			left:100%;
			top: -40px;
			margin-left: 14px;
		}
	}

	.header_links{
		position:absolute;
		left:68%;
		top:50%;
		@include transform(translate(-50%, -50%));
		width: 720px;
		z-index: 10;
		.header_link{
			color:#fff;
			display:inline-block;
			margin:0 14px;
			font-size:18px;
			padding:10px 0;
			position: relative;
			text-decoration: none;
			&:after{
				content: "";
				position: absolute;
				top: 30px;
				left:50%;
				@include transform(translate(-50%, 0));
				width:102%;
				display: none;
				background: #fff;
				height: 3px;
			}
			&.selected{
				&:after{
					display: block;
					height: 3px !important;
				}
			}
			&:hover{
				&:after{
					display: block;
					height: 1px;
				}
			}
			&[data-page="methodology"], &[data-page="press"]{
				opacity: .7;
				font-family: "robotoregular";
				font-size: 16px;
				&.selected{
					opacity: 1;
				}
			}
		}
	}
}