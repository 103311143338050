@import 'mixins/global';

html, body{
	font-size:13px;
	line-height:16px;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	width:100%;
	height:100%;
	font-family: 'roboto_slabregular';
}

select{
	-webkit-appearance:none;
	-moz-appearance:none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

#App{
	width:100%;
	height:100%;
	position:absolute;
	text-align:center;
	width:100%;
	height:100%;
	overflow:hidden;
	opacity:1;
	@include transition((opacity), 0.3s, linear);

	#Container{
		position:absolute;
		top:$headerHeight;
		bottom:0px;
		width:100%;
	}
}

.ui-autocomplete{
	max-height: 300px;
	overflow: auto;
}

.og_block{
	background:#efefeb;
	margin-top: 25px;
	position: relative;
	border-radius: 0 0 14px 14px;
	&:before{
		content:"";
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 20px 20px 20px;
		border-color: transparent transparent #efefeb transparent;
		position: absolute;
		bottom: 100%;
		@include transform(translate(-50%, 0));
		@include transition((all), 0.3s, ease-in-out);
	}
	&[data-block-index="1"]{
		&:before{
			left: 16.66667%;
		}
	}
	&[data-block-index="2"]{
		&:before{
			left: 50%;
		}
	}
	&[data-block-index="3"]{
		&:before{
			left: 82.6666667%;
		}
	}
	.block_content{
		padding:10px;
		.content_indicator{
			height:40px;
			line-height:40px;
			width:100%;
			text-align:left;
			&[data-hasdata="false"]{
				display: none;
			}
			.indicator_label{
				display:inline-block;
				vertical-align:top;
				height:100%;
				width:36%;
				position:relative;
				text-align: right;
				.label_value{
					position:absolute;
					right:10px;
					top:50%;
					font-size:14px;
					font-family: "robotoregular";
					color:$colorBlue;
					line-height:20px;
					@include transform(translate(0, -50%));
					.talent_help{
						position: relative;
						left: 0px;
						top: -2px;
						width: 18px;
						height: 18px;
						font-family: "montserratbold";
						font-size: 13px;
						background:$colorBlue;
						color:#fff;
						text-align: center;
						line-height: 18px;
						border-radius: 18px;
						display: inline-block;
						cursor: pointer;
						.help_tooltip{
							position: absolute;
							left: 50%;
							bottom: 100%;
							margin-bottom: 10px;
							@include transform(translate(-50%, 0));
							background:$colorBlue;
							border-radius: 10px;
							padding: 6px;
							color: #fff;
							font-size: 12px;
							font-family: "robotoregular";
							line-height: 14px;
							display: none;
							width: 140px;
							&:after{
								content:"";
								width: 0;
								height: 0;
								border-style: solid;
								border-width: 6px 6px 0 6px;
								border-color: $colorBlue transparent transparent transparent;
								position: absolute;
								left: 50%;
								top: 100%;
								@include transform(translate(-50%, 0));
							}
						}
						&:hover{
							.help_tooltip{
								display: block;
							}
						}
					}
				}
			}
			.indicator_bar_wrapper{
				display:inline-block;
				vertical-align:top;
				height:100%;
				line-height:30px;
				width:63%;
				position:relative;
				.indicator_bar{
					width:100%;
					background:#91b6cc;
					height:3px;
					position:absolute;
					left:0px;
					top:50%;
					@include transform(translate(0, -50%));
					.bar_legend{
						position:absolute;
						top:50%;
						@include transform(translate(0, -50%));
						width: 18px;
						height: 18px;
						background: #91b6cc;
						border-radius: 18px;
						&:after{
							content: "";
							position: absolute;
							left: 50%;
							top: 50%;
							@include transform(translate(-50%, -50%));
						}
						&.legend_less{
							left:0px;
							&:after{
								background: url("img/level-min.svg") no-repeat 0 0;
								background-size: 100% 100%;
								width: 18px;
								height: 18px;
							}
							
						}
						&.legend_plus{
							right:0px;
							&:after{
								background: url("img/level-max.svg") no-repeat 0 0;
								background-size: 100% 100%;
								width: 18px;
								height: 18px;
							}
						}
					}
					.indicator_bar_inner{
						position: absolute;
						left: 50%;
						top: 0px;
						height: 100%;
						width: 90%;
						@include transform(translate(-50%, 0));
					}
					.bar_point{
						position:absolute;
						width:17px;
						height:25px;
						border-radius:14px;
						background:url("img/level-pin-cyan.svg") no-repeat 0 0;
						background-size: 100% 100%;
						top:50%;
						@include transform(translate(0, -50%));
						@include transition((all), 0.3s, ease-in-out);
						margin-top: -8px;
						z-index: 10;
						left: 0%;
						&.bar_point2{
							background:url("img/level-pin-orange.svg") no-repeat 0 0;
							background-size: 100% 100%;
							z-index: 5;
						}
					}
				}
			}
		}
	}
	&.displayed{
		display:block;
	}
}

.link_shares{
	display:inline-block;
	margin:0 14px;
	position: relative;
	top: -4px;
	.share_bt{
		display: inline-block;
		margin: 0 2px;
		vertical-align: top;
		width: 23px;
		height: 23px;
		cursor: pointer;
		&[data-share="tw"]{
			background: url("img/share-tw-white.svg") no-repeat 0 0;
			background-size: 100% 100%;
		}
		&[data-share="fb"]{
			background: url("img/share-fb-white.svg") no-repeat 0 0;
			background-size: 100% 100%;
		}
		&[data-share="in"]{
			background: url("img/share-in-white.svg") no-repeat 0 0;
			background-size: 100% 100%;
		}
		&:hover{
			opacity: .7;
		}
	}
}

body .ui-menu .ui-menu-item{
	height: 36px;
	line-height: 36px;
	color: $colorBlue2;
	padding: 0;
	margin: 0;
	font-family: "roboto_slabregular";
	&:nth-child(even){
		background: #efefeb;
	}
	div{
		border:0px;
		padding: 0 10px;
		font-size: 15px;
		&.ui-state-active{
			background: $colorBlue2;
			border:0px solid;
			color: #fff;
		}
	}
}