@import 'mixins/global';

#App[data-page="home"]{
	#HomeContainer{
		position: relative;
		left: 0px;
		width:100%;
		height: 453px;

		#WorldNavigation{
			position: absolute;
			left: 10%;
			top: 20px;
			@include transform(translate(-50%, 0));
			width: 30px;
			z-index: 1000;
			.nav_bt{
				width: 28px;
				height: 28px;
				border-radius: 28px;
				cursor: pointer;
				display: inline-block;
				vertical-align: top;
				margin-bottom: 5px;
				&[data-bt="zoomin"]{
					background: url("img/map-zoomin.svg") no-repeat 0 0 $colorBlue;
					background-size:100% 100%;
				}
				&[data-bt="zoomout"]{
					background: url("img/map-zoomout.svg") no-repeat 0 0 $colorBlue;
					background-size:100% 100%;
				}
				&[data-bt="zoomzero"]{
					background: url("img/map-zoomzero.svg") no-repeat 0 0 $colorBlue;
					background-size:100% 100%;
				}
				&:hover{
					opacity: .8;
				}
			}
		}

		#WorldMap{
			width:100%;
			height: 100%;
			position: absolute;
			left: 0px;
			top:0px;
			background: #efefeb;
			.highcharts-map-navigation{
				display: none;
			}
			.highcharts-series-0{
				g{
					@include transition((all), 0.3s, linear);
					path{
						stroke-width:1px;
					}
				}
			}
			.highcharts-markers{
				.highcharts-point{
					@include transform(translate(-10px, -20px));
				}
				&.highcharts-series-2{
					image{
						opacity: .4;
						cursor: pointer;
						@include transition((all), 0.3s, linear);
						&[data-selected="true"]{
							opacity: 1;
							width:40px;
							height: 48px;
							@include transform(translate(-20px, -40px));
						}
						&:hover{
							opacity: 1;
						}
					}
				}
			}
		}

		.home_mainblock{
			position: absolute;
			top:0%;
			@include transform(translate(0, -20px));
			right:5%;
			width:280px;
			border:6px solid $colorBlue;
			background:#fff;
			color:#03619d;
			padding: 10px 20px 30px;
			border-radius: 18px;
			min-height: 420px;

			.mainblock_step{
				display: none;
				&.displayed{
					display: block;
				}
			}
			
			.mainblock_choice{
				margin-top: 14px;
				.choice_label{
					font-size: 16px;
					line-height: 20px;
				}
				.choice_bt{
					background: $colorOrange;
					color: #fff;
					font-family: "montserratbold";
					display: inline-block;
					width: 212px;
					height: 40px;
					line-height: 40px;
					font-size: 14px;
					color: #fff;
					text-decoration: none;
					text-transform: uppercase;
					margin-top: 8px;
					border-radius: 3px;
					span{
						padding-left: 14px;
						display: inline-block;
						position: relative;
						&:before{
							content:"";
							width:19px;
							height: 17px;
							position: absolute;
							left: 0px;
							margin-left: -10px;
							top: 50%;
							@include transform(translate(0, -50%));
						}
					}
					&[data-choice="imbalances"]{
						span{
							&:before{
								content:"";
								background: url("img/homepage-icon-imbalances.svg") no-repeat 0 0;
								background-size: 100% 100%;
							}
						}
					}
					&[data-choice="career"]{
						span{
							&:before{
								content:"";
								background: url("img/homepage-icon-career.svg") no-repeat 0 0;
								background-size: 100% 100%;
								
							}
						}
					}
					&:hover{
						opacity: .8;
					}
				}
			}

			.mainblock_locateuser{
				margin-top: 60px;
				font-family: "montserratlight";
				font-size: 11px;
				text-transform: uppercase;
				position: relative;
				&:before{
					content:"";
					position: absolute;
					left: 50%;
					bottom: 100%;
					margin-bottom: 5px;
					background: url("img/map-pin@2x.png") no-repeat 0 0;
					background-size: 100% 100%;
					width: 35px;
					height: 42px;
					@include transform(translate(-50%, 0));
				}
				.locateuser_country{
					font-family: "roboto_slabregular";
					font-size: 20px;
					text-transform: capitalize;
				}
			}

			.mainblock_subnationals{
				margin-top: 16px;
				font-family: "montserratlight";
				font-size: 11px;
				text-transform: uppercase;
				display: none;
				.subnationals_selectbox{
					border:1px solid $colorBlue2;
					height: 36px;
					width:212px;
				}
			}

			.mainblock_changelocationbt{
				margin-top: 16px;
				display: inline-block;
				font-family: "robotoregular";
				font-size: 14px;
				text-decoration: underline;
				color:$colorBlue2;
				cursor: pointer;
				&:hover{
					opacity: .8;
				}
			}

			.pickmap_container{
				position: absolute;
				left: 50%;
				top: 50%;
				@include transform(translate(-50%, -50%));
				.pickmaplabel{
					position: relative;
					font-size: 16px;
					line-height: 20px;
					&:before{
						content:"";
						position: absolute;
						left:50%;
						bottom: 100%;
						background: url("img/map-pin@2x.png") no-repeat 0 0;
						background-size: 100% 100%;
						width: 28px;
						height: 34px;
						@include transform(translate(-50%, 0));
						margin-bottom: 5px;
					}
				}
				.pickmapinput{
					margin-top: 14px;
					#LocationSelectbox{
						padding: 10px 30px 10px 10px;
						background: url("img/arrow-dropdown-solid.svg") no-repeat 93% 14px;
					}
				}
				.pickmap_cancelbt{
					margin-top: 10px;
					color:$colorBlue2;
					display: block;
					text-decoration: underline;
					cursor: pointer;
				}
			}
		}
	}

	#HomeFooter{
		text-align: center;
		width: 100%;
		padding: 14px 0;
		.footer_link{
			font-size: 15px;
			color:$colorBlue3;
			font-family: "robotoregular";
			margin: 0 10px;
			cursor: pointer;
		}
		.link_shares{
			margin: 0;
			.share_bt{
				&[data-share="tw"]{
					background: url("img/share-tw-blue.svg") no-repeat 0 0;
					background-size: 100% 100%;
				}
				&[data-share="fb"]{
					background: url("img/share-fb-blue.svg") no-repeat 0 0;
					background-size: 100% 100%;
				}
				&[data-share="in"]{
					background: url("img/share-in-blue.svg") no-repeat 0 0;
					background-size: 100% 100%;
				}
				&:hover{
					opacity: .7;
				}
			}
		}
		.footer_partners{
			display: block;
			text-align: center;
			margin-top: 30px;
			.partner{
				display: inline-block;
				vertical-align: top;
				margin: 0 10px;
				&[data-partner="oecd"]{
					background:url("img/logo-oecd-color@2x.png") no-repeat 0 0;
					background-size: 100% 100%;
					width: 122px;
					height: 29px;
					position: relative;
					top: -4px;
				}
				&[data-partner="jpmorgan"]{
					background:url("img/logo-jpmc_nsaw_v.png") no-repeat 0 0;
					background-size: 100% 100%;
					width: 153px;
					height: 30px;
				}
				.partner_label{
					font-family: "robotoregular";
					font-size: 12px;
					color: $colorBlue;
					position: relative;
					top: -20px;
				}
			}
		}
	}

	&[data-autolocated="false"]{
		.welocatedyou{
			display: none;
		}
	}
}